html {
    width: 100%;
    height: 100%;
}

body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font: {
        family: 'Noto Serif', serif;
        weight: 400;
    }
    line-height: 1.4;
    color: $color-text-primary;
    background-color: $color-background;
    -webkit-font-smoothing: antialiased;
}

a {
    color: $color-text-primary;
    text-decoration: none;
}

.artist {
    font-weight: 700;
}

@media screen and (max-width: 320px) {
    body {
        font-size: 0.75rem;
    }
}

@media screen and (max-width: 414px) {
    body {
        font-size: .8125rem;
    }
}