.holder {
    max-width: 25.25rem;
    margin: 0 auto;
    padding: 2rem;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.holder__logo {
    margin-bottom: 2rem;
}